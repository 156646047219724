import {Header } from './components/Header';
import {MainSection} from "./components/MainSection";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import SuccessMessage from "./components/SuccessMessage";

function App() {
  return (
      <Router>
          <div className="App">
              <Header />
              <Routes>
                  <Route path="/" element={<MainSection />} />
                  <Route path="/formulario-enviado-exitosamente" element={<SuccessMessage />} />
              </Routes>
          </div>
      </Router>
);
}

export default App;
