import React from "react";
import '../Assets/css/Form.css';
import {useNavigate} from "react-router-dom";
export const Success = () => {
    const navigate = useNavigate();

    const handleBack = () => {
            navigate('/');
    }

    return (
        <div className="form-container">
            <h2>Formulario enviado exitosamente.</h2>
            <p>Gracias por tu tiempo.</p>
            <button className='btn-back' onClick={handleBack}> Regresar</button>
        </div>
    );
}