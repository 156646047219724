import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'rut.js';
import * as RUT from "rut.js";
import '../Assets/css/Form.css';
import { useNavigate } from "react-router-dom";

export const Formulario = () => {
    const { register, handleSubmit, formState: { errors }, setValue, trigger } = useForm();
    const [options, setOptions] = useState([]);
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [recaptchaReady, setRecaptchaReady] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('http://192.34.63.237:2024/api/v1/type/')
            .then(response => {
                const filteredData = response.data.filter(item =>
                    [31, 34, 37].includes(item.id)
                );
                setOptions(filteredData);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    console.error('Error getting location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=6LeagwsqAAAAAOVOiUP5fHKYcAuWEE1a3ISJyulE";
        script.async = true;
        script.onload = () => setRecaptchaReady(true);
        document.body.appendChild(script);
    }, []);

    const clearForm = () => {
        setValue('rut', '');
        setValue('nombre', '');
        setValue('telefono', '');
        setValue('email', '');
        setValue('address', '');
        setValue('aceptoPolitica', false);
    };

    const onSubmit = async (data) => {
        if (!data.aceptoPolitica) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Debes aceptar la Política de Privacidad para continuar',
            });
            return;
        }

        if (!recaptchaReady) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'reCAPTCHA no está listo. Por favor, inténtalo de nuevo.',
            });
            return;
        }

        // Obtén el token de reCAPTCHA
        const token = await window.grecaptcha.execute('6LeagwsqAAAAAOVOiUP5fHKYcAuWEE1a3ISJyulE', { action: 'submit' });

        // Separar RUT y DV
        const [rut, dv] = data.rut.split('-');
        const currentDate = new Date().toISOString().split('T')[0];

        const prospectData = {
            created_at: new Date(),
            updated_at: new Date(),
            first_name: data.nombre,
            address: data.address,
            phone: `+56${data.telefono.replace(/^(\+56|56)/, '')}`,
            rut: rut,
            dv: dv,
            latitude: -33.4489,
            longitude: -70.6693,
            id_commune: 1,
            id_region: 1,
            user_created: 'contacto@redarroba.cl',
            schedule_date: currentDate,
            call_center: '',
            validated_address: false,
            current_plan: '',
            lccid: '',
            document_number: '',
            user_assigned: '',
            direct_sale: false,
            from_entel: false,
            terrain: false,
            id_subsidiary_id: null,
            subsidiary_id: null,
            second_line: '',
            from_wom: false,
            last_name: '',
            id_type: 31,
            id_chipplans_id: null,
            recaptchaToken: token, // Incluye el token de reCAPTCHA
        };

        axios.post('http://192.34.63.237:2024/api/v1/prospect/', prospectData)
            .then(response => {
                const prospectId = response.data.id;

                const scheduleData = {
                    created_at: new Date(),
                    updated_at: new Date(),
                    observation: 'Venta Web',
                    user_created: 'contacto@redarroba.cl',
                    schedule_date: currentDate,
                    sale_date: currentDate,
                    pay_date: currentDate,
                    column17: '',
                    column18: '',
                    column19: '',
                    origen_prospecto: 'web_form',
                    id_prospect: prospectId,
                    id_status: 45,
                };

                return axios.post('http://192.34.63.237:2024/api/v1/schedule/', scheduleData);
            })
            .then(response => {
                navigate('/formulario-enviado-exitosamente')
                clearForm();
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un error al enviar los datos',
                    confirmButtonColor: '#ffd000',
                });
                console.error('Error submitting data:', error);
            });
    };

    const handleRutChange = (e) => {
        let rut = e.target.value.replace(/[^\dkK]/g, '');
        if (rut.length > 1) {
            rut = rut.slice(0, -1) + '-' + rut.slice(-1);
        }
        setValue('rut', rut);
        trigger('rut');
    };

    const handlePhoneChange = (e) => {
        let phone = e.target.value.replace(/[^\d]/g, '');
        if (phone.length > 9) {
            phone = phone.slice(0, 9);
        }
        setValue('telefono', phone);
        trigger('telefono');
    };

    return (
        <div className="form-container">
            <h2>Cotiza tu Alarma y deja tus datos.</h2>
            <form id="prosegur-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="RUT"
                        {...register('rut', {
                            required: 'Este campo es obligatorio',
                            validate: (value) => RUT.validate(value) || 'RUT no válido'
                        })}
                        onChange={handleRutChange}
                    />
                    {errors.rut && <p className="error">{errors.rut.message}</p>}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Nombre y Apellido"
                        {...register('nombre', { required: 'Este campo es obligatorio' })}
                    />
                    {errors.nombre && <p className="error">{errors.nombre.message}</p>}
                </div>
                <div className="form-group">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '5px' }}>+56</span>
                        <input
                            type="text"
                            placeholder="Teléfono"
                            {...register('telefono', {
                                required: 'Este campo es obligatorio',
                                pattern: {
                                    value: /^9\d{8}$/,
                                    message: 'Número de teléfono no válido. Debe comenzar con 9 y tener 9 dígitos en total.'
                                }
                            })}
                            onChange={handlePhoneChange}
                            style={{ flex: 1 }}
                        />
                    </div>
                    {errors.telefono && <p className="error">{errors.telefono.message}</p>}
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        placeholder="Email"
                        {...register('email', {
                            required: 'Este campo es obligatorio',
                            pattern: {
                                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                message: 'Correo electrónico no válido'
                            }
                        })}
                    />
                    {errors.email && <p className="error">{errors.email.message}</p>}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Dirección"
                        {...register('address', {
                            required: 'Este campo es obligatorio'
                        })}
                    />
                    {errors.address && <p className="error">{errors.address.message}</p>}
                </div>
                <div className="form-group">
                    <label className="form-label">
                        <input
                            type="checkbox"
                            {...register('aceptoPolitica', { required: 'Debes aceptar la Política de Privacidad' })}
                        /> He leído y acepto la <a className="politicas"
                                                   href="https://www.prosegur.cl/prosegur-chile/paginas-composiciones/formulario-cl/modal-alarmas.html"
                                                   target="_blank" rel="noreferrer"
                    >Política de Privacidad.</a>
                    </label>
                    {errors.aceptoPolitica && <p className="error">{errors.aceptoPolitica.message}</p>}
                </div>
                <button
                    type="submit"
                    className="g-recaptcha"
                    data-sitekey="6LeagwsqAAAAAOVOiUP5fHKYcAuWEE1a3ISJyulE"
                    data-callback='onSubmit'
                    data-action='submit'
                >
                    Enviar
                </button>
            </form>
        </div>
    );
};

export default Formulario;
