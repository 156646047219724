// Header.js
import React from 'react';
import '../Assets/css/Header.css';
 import logo from '../Assets/img/logo.png';
export const Header = () => {
    return (
        <header className="header">
            <img src={logo} alt="Logo" className="logo" />
        </header>
    );
};


