// MainSection.js
import React from 'react';
import {Banner} from './Banner';
import '../Assets/css/MainSection.css';
import {Formulario} from "./Formulario";

export const MainSection = () => {
    return (
        <div className="main-section">
            <div className="content-container">
                <Banner />
                <Formulario />
            </div>
        </div>
    );
};

