// Banner.js
import React from 'react';
import '../Assets/css/Banner.css';

export const Banner = () => {
    return (
        <div className="banner">
            <div className="promo-text">
                <h1>Elige Prosegur Alarmas y protege tu hogar con profesionales</h1>
                <div style={{backgroundColor:'#7CCACE',fontSize:'15px',marginTop:'10px',marginBottom:'10px',width:'150px',padding:'5px'}}>Ofertas Especiales</div>
                <img src="https://www.prosegur.cl/dam/jcr:d4b4502b-39f9-4d31-99ba-812b5e1acdde/Promo-sola.2024-06-29-00-32-13.png" alt=""/>
            </div>
        </div>
    );
};


