import React from 'react';
import {Banner} from "./Banner";
import {Formulario} from "./Formulario";
import {Success} from "./Success";

const SuccessMessage = () => {
    return (
        <div className="main-section">
            <div className="content-container">
                <Banner/>
                <Success/>
            </div>
        </div>
    );
};

export default SuccessMessage;

